import React from 'react'
import { graphql, Link } from 'gatsby'
import { ImageAndForm } from '../acf/ImageAndForm'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import ProductCategoryBreadcrumbs from '../components/ProductCategoryBreadcrumbs'
import ProductList from '../components/ProductList'
import ProductCategoriesNavigation from '../components/ProductCategoriesNavigation'
import SubCategoriesList from '../components/SubCategoriesList'
import { Banner } from '../acf/Banner'
import { Gallery } from '../acf/Gallery'
import { decodeEntities } from '../utils/htmlParse'
import { ImagePass } from '../components/Image/ImagePass'
import './productcategory.scss'

const ProductCategory = ({ data, location, pageContext }) => {
  const {
    wordpressWpServicesCat: productCat,
    wordpressWpSettings,
    wordpressAcfOptions,
    allWordpressWpServicesCat,
  } = data
  if (!productCat) return null
  const allProductCategories = allWordpressWpServicesCat.edges.sort((a, b) => {
    const aOrder = a.node.acf.order
    const bOrder = b.node.acf.order
    const sortNotNull = (bOrder !== null) - (aOrder !== null)
    const sortNum = parseInt(aOrder) - parseInt(bOrder)
    return sortNotNull || sortNum
  })
  const { name: title, slug, description, acf = {} } = productCat
  const {
    bannerImage,
    pageContent,
    types,
    materials,
    rating,
    logoGallery,
    gallery
  } = acf
  const { siteTitle } = wordpressWpSettings
  const { options = {} } = wordpressAcfOptions
  const { contactForm } = options

  // Find top level parent category using slug
  const parentSlugRegex = new RegExp(
    `(/${productCat.taxonomy.slug}/).*?(?=/)`,
    'gs'
  )
  const topLevelParentCatSlug = productCat.link
    .match(parentSlugRegex)[0]
    .split('/')[2]
  const topLevelParentCat = allProductCategories.find(
    ({ node: cat }) => cat.slug === topLevelParentCatSlug
  )
  const {
    categoryProducts = true, // will receive products assigned to this category
  } = pageContext
  const {
    node: {
      name: topLevelParentTitle,
      description: topLevelParentDescription,
      acf: topLevelParentCatAcf = {},
    },
  } = topLevelParentCat
  const { bannerImage: topLevelParentBannerImage } = topLevelParentCatAcf

  const getChildCategories = () => {
    // Find child categories of current category
    const childCategories = allProductCategories.filter(({ node: cat }) => {
      const parentCat = cat.parent_element
      return parentCat && parentCat.id === productCat.id
    })
    return childCategories
  }

  const getTopLevelParentChildren = () => {
    // Get child elements of top level category
    // Loop through child elements to get child elements for each
    // Stop (Max 2 levels deep - P>C>C)
    const topLevelParentChildren = allProductCategories
      .filter(({ node: cat }) => {
        const parentCat = cat.parent_element
        return parentCat && parentCat.id === topLevelParentCat.node.id
      })
      .map(({ node: child }) => {
        const thirdLevelCategories = allProductCategories.filter(
          ({ node: cat }) => {
            const parentCat = cat.parent_element
            return parentCat && parentCat.id === child.id
          }
        )
        if (thirdLevelCategories.length === 0) return child
        return {
          ...child,
          thirdLevelCategories,
        }
      })
    return topLevelParentChildren
  }

  const childCategories = getChildCategories()
  const topLevelParentChildren = getTopLevelParentChildren()

  return (
    <Layout location={location}>
      <SEO
        title={`${decodeEntities(title)} | ${decodeEntities(siteTitle)}`}
        pathname={location.pathname}
      />
      <Banner
        image={bannerImage || topLevelParentBannerImage}
        heading={topLevelParentTitle}
        headingTag="span"
        subtitle={topLevelParentDescription}
      />
      <section className="product-category-container">
        <div className="wrapper">
          <ProductCategoriesNavigation
            location={location}
            topLevelParentChildren={topLevelParentChildren}
          />
          <div className="product-category-content-container">
            <ProductCategoryBreadcrumbs location={location} />
            <h1
              className="product-category-heading"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            {pageContent && (
              <div
                className="product-category-content"
                dangerouslySetInnerHTML={{ __html: pageContent }}
              />
            )}
            {(types || materials || rating) && (
              <div className="product-category-details">
                {types && (
                  <div className="product-category-details-grid-item product-category-types">
                    <h3 className="product-category-details-grid-item-heading">
                      Types:
                    </h3>
                    <p dangerouslySetInnerHTML={{ __html: types }} />
                  </div>
                )}
                {materials && (
                  <div className="product-category-details-grid-item product-category-materials">
                    <h3 className="product-category-details-grid-item-heading">
                      Materials:
                    </h3>
                    <p dangerouslySetInnerHTML={{ __html: materials }} />
                  </div>
                )}
                {rating && (
                  <div className="product-category-details-grid-item product-category-rating">
                    <h3 className="product-category-details-grid-item-heading">
                      Rating:
                    </h3>
                    <p dangerouslySetInnerHTML={{ __html: rating }} />
                  </div>
                )}
              </div>
            )}
            {logoGallery && (
              <div className="product-category-logos">
                {Object.keys(logoGallery).length > 0 &&
                  logoGallery?.map((item, index) => {
                    return item.link ? (
                      <Link to={item.link.url} key={index}>
                        <div className="image-container">
                          <ImagePass src={item.logo} />
                        </div>
                      </Link>
                    ) : (
                      <div className="image-container" key={index}>
                        <ImagePass src={item.logo} />
                      </div>
                    )
                  })}
              </div>
            )}
            {gallery && (
              <Gallery gallery={gallery} id={productCat.id} />
            )}
            <SubCategoriesList categories={childCategories} />
            {categoryProducts.length > 0 && (
              <div className="product-category-product-range">
                <h3 className="product-category-product-range-heading">
                  {/* eslint-disable-next-line */}
                  {decodeEntities(title)} Product Range
                </h3>
                <ProductList products={categoryProducts} />
              </div>
            )}
          </div>
        </div>
      </section>
      <ImageAndForm
        heading={contactForm.heading}
        content={contactForm.content}
        form="contact"
        image={contactForm.image}
        location={location}
      />
    </Layout>
  )
}

export default ProductCategory

export const pageQuery = graphql`
  query ProductCategoryById($id: String!) {
    site {
      siteMetadata {
        functionsUrl
        productsSlug
      }
    }
    wordpressWpSettings {
      siteTitle: title
    }
    wordpressAcfOptions {
      options {
        contactForm {
          heading
          content
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    wordpressWpServicesCat(id: { eq: $id }) {
      id
      name
      slug
      description
      link
      taxonomy {
        slug
      }
      acf {
        types
        materials
        rating
        pageContent
        logoGallery {
          logo {
            localFile {
              childImageSharp {
                fluid(maxWidth: 300, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          link {
            url
          }
        }
        bannerImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        gallery {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1400, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    allWordpressWpServicesCat {
      edges {
        node {
          id
          wordpress_id
          link
          name
          description
          slug
          parent_element {
            id
          }
          taxonomy {
            slug
          }
          acf {
            order
            bannerImage {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
            featureImage {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
